// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
window.jQuery = window.$ = $;

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

import 'trix'

require("@rails/actiontext")
document.addEventListener('trix-initialize', () => {
  if (document.querySelector('#article_body')) {
    import('trix/dist/trix.css').then(css => {

      const trixEditor = document.querySelector('#article_body');
      const trixEditorHeight = trixEditor.offsetHeight;
      trixEditor.style.height = trixEditorHeight + 'px';

    });
  }
});